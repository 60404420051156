body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-header-nav-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* position: relative; */
}
.app-header-navigation-body.navbar-nav {
  width: 100%;
}
.app-header-menu-navbar-body {
  display: flex;
  align-items: center;
  gap: 15px;
}
.app-header-logo {
  width: 100%;
}
.app-logo-link.nav-link {
  display: flex;
  justify-content: center;
}
.app-header-first-level-container {
  padding: 10px 40px;
  background: #1790B6;
}
.app-header-first-level-body {
  display: flex;
  justify-content: space-between;
  gap: 45px;
}
.app-header-first-level {
  color: #ffffff;
  font-weight: 600;
  font-family: 'Poppins';
  font-style: normal;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
}
.app-header-navbar {
  /* background: #1790b640 !important; */
  background: transparent !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.app-menu-item.nav-link {
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  color: #270BD8;
  font-weight: 500;
  text-transform: uppercase;
}
.app-menu-item.nav-link.active {
  background: #270BD8;
  color: #ffffff;
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 600;
}
.app-logo-link.nav-link {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -100%;
  width: 16%;
}
.app-header-container.container {
  position: relative;
}
.app-header-first-level svg {
  font-size: 30px;
}
/* .app-homepage-cover-container {
  height: 100vh;
} */
.app-homepage-cover-textual-container {
  padding: 50px;
}
.app-cover-textual-section-title {
  font-size: 45px;
  color: #ffffff;
  font-weight: 700;
}
body, html {
  font-family: 'Poppins' !important;
  height: 100%;
}
.app-cover-textual-section-content {
  font-size: 18px;
  color: #fff;
  text-align: justify;
}
.app-cta-btns-container {
  padding: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app-cta-btn {
  padding: 10px 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 17px;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  justify-content: center;
}
.app-cta-btn.cta-phone-btn {
  background: #1790B6;
}
.app-cta-btn svg {
  font-size: 30px;
}
.app-cta-btn.cta-email-btn {
  background: #270BD8;
}
.app-service-card {
  padding: 20px 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid #1790B6;
  position: relative;
}
.app-homepage-cover-row {
  z-index: 99;
  position: relative;
}
.app-services-container.container {
  padding: 50px 0;
}
.service-in-list {
  text-align: left;
  margin: 20px 0;
}
.app-service-card-title {
  margin: 15px 0;
  color: #ffffff;
  font-weight: 600;
}
#decoration-section-with-quotation-btn {
  background: #270BD8;
  padding: 20px 70px;
}
.decoration-section-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  color: #FFFFFF;
  text-align: center;
  padding: 20px 0;
}
#decoration-section-with-quotation-btn .app-navbar-link.phone-cta-btn {
  border: 1px solid #ffffff !important;
  border-width: 2px !important;
}
#decoration-section-with-quotation-btn .app-cta-link.phone-cta-btn{
  background: transparent !important;
  border-width: 2px;
}
.decoration-textual-content p {
  color: #ffffff;
}
.decoration-textual-content {
  margin-bottom: 50px;
}
.decoration-section-actions-btn-col .app-cta-btn.cta-email-btn {
  background: #ffffff !important;
  color: #270BD8;
}
#plumber-section {
  padding: 50px 0;
  background: #1790B630;
}
.plumber-section-body {
  background: #1790B6;
  padding: 50px 15px;
  padding-left: 11%;
}
.plumber-section-image {
  position: absolute;
  left: 0;
  width: 40%;
  top: 0;
}
.plumber-section-row {
  position: relative;
}
#locksmith-section {
  padding: 50px 0;
  background: #270BD830;
}
.locksmith-section-row {
  position: relative;
}
.locksmith-section-body {
  background: #270BD8;
  padding: 50px 15px;
  padding-right: 11%;
  text-align: justify;
}
.locksmith-section-image {
  position: absolute;
  right: 0;
  width: 40%;
  top: 0;
}
.locksmith-section-textual-container p {
  color: #ffffff;
}
.locksmith-section-body .app-cta-btn.cta-email-btn {
  background: #ffffff;
  color: #270BD8;
}
.plumber-section-body .app-cta-btn.cta-phone-btn {
  background: #ffffff;
  color: #1790B6 !important;
}
.pricing-geo-image {
  width: 100%;
}
.locksmith-section-body .app-cta-btn.cta-email-btn {
  background: #ffffff;
  color: #270BD8;
}
.plumber-section-body .app-cta-btn.cta-phone-btn {
  background: #ffffff;
  color: #1790B6 !important;
}
.pricing-geo-image {
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  margin: 10px 0;
}
#pricing-section {
  padding: 50px 0;
}
.pricing-section-title {
  text-align: center;
  color: #1790B6;
  font-weight: 600;
  margin-bottom: 40px;
}
.pricing-table-container {
  padding: 20px 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  border: 1px solid #1790B6;
}
.pricing-item-title {
  color: #270BD8;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
}
.pricing-item-card {
  margin: 20px 10px;
  border-radius: 10px;
  text-align: center;
  box-shadow: rgba(39, 11, 216, 0.25) 0px 30px 60px -12px inset, rgba(23, 144, 182, 0.3) 0px 18px 36px -18px inset;
  padding: 20px;
  border: 1px solid #270BD8;
}.pricing-row {
  align-items: center;
}
.pricing-item-card--title {
  color: #1790B6;
  font-weight: 700;
}
.pricing-item {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  margin: 10px 0;
  font-weight: bold;
  color: #1790B6;
  font-size: 25px;
}
.pasuneurode-plus {
  display: block;
  width: 100%;
}
.pricing-section-container .cta-row {
  flex-direction: column !important;
  margin: 0 !important;
}
.pricing-section-container .cta-col {
  width: 100%;
  margin-bottom: 10px;
}
.plumber-section-image-bis {
  margin: 15px 0;
  width: 100%;
}
.locksmith-section-image-bis {
  margin: 10px 0;
  width: 100%;
}
.plumber-section-textual-container p {
  text-align: justify;
}

.footer {
  background: #000;
}
.footer-first-level-container {
  margin-bottom: 45px;
  padding: 20px 60px;
}
.footer-fl-item-section-title {
  color: #1790B6;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  text-transform: uppercase;
}
.app-footer-brand-img {
  width: 30%;
  margin-bottom: 15px;
}
.footer-brand-text {
  color: #ffffff80;
  font-family: Poppins;
  font-size: 14px;
  margin-top: 4%;
  text-align: justify;
}
.footer-contact-item-title {
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: 0!important;
  text-align: justify;
}
.footer-contact-cta, .footer-contact-item-label {
  color: #ffffff80;
  font-family: Poppins;
  font-size: 14px;
  text-align: justify;
  text-decoration: none;
}
.footer-service-item-link {
  color: #ffffff80;
  font-family: Poppins;
  font-size: 14px;
  margin-top: 4%;
  text-align: justify;
  text-decoration: none;
}
.footerapp-services-ul {
  list-style: none!important;
  padding-left: 0!important;
}
.second-level-container {
  padding: 20px 60px;
}
.footer-sl-body {
  color: #ffffff80;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
}
.author-link {
  color: #ffffff80;
  text-decoration: none;
}
.footer-divider {
  background: #ffffff50;
  height: 1px;
  width: 100%;
}
.google-badge-image {
  position: fixed;
  bottom: 10%;
  right: 1%;
  width: 7%;
  border: 3px solid #FFF !important;
  border-radius: 50%;
  z-index: 9999;
}
.App-Router-Dom {
  position: relative;
}
.global-cta-btn-container {
  position: fixed;
  bottom: 30%;
  right: 2.5%;
}
.global-cta-btn {
  display: flex;
  font-size: 35px;
  width: 50px;
  height: 50px;
  background: #1790B6;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.app-global-phone-icon {
  color: #ffffff;
}
.global-cta-btn.quotation-btn {
  background: #270BD8;
}
.phone-link {
  color: #1790B6;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-size: 19px;
  line-height: 27px;
  text-transform: uppercase;
  text-decoration: none;
}
.app-service-card-image {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -10%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 50%;
  border: 5px solid #ffffff;
}

section#all-services {
  padding: 50px 70px;
  background: #1790B640;
}
.all-services-section-title-container {
  display: flex;
  margin-bottom: 30px;
}
.all-services-section-title-description-body {
  width: 100%;
}
.what-we-offer {
  color: #1790B6;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
}
.all-services-section-title {
  font-size: 25px;
  color: #270BD8;
  font-weight: 600;
}
.all-services-section-title-description-body p {
  text-align: justify;
  position: relative;
}
.all-services-section-title-description-body p:before {
  content: "";
  position: absolute;
  width: 25%;
  height: 9px;
  background: #270BD8;
  bottom: -10%;
}
.service-item-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: all .7s ease;
}
.service-item-content-body-container {
  position: relative;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 20px;
}
.service-item-title-and-description-container {
  background-color: #1790B695;
  padding: 15px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  border-style: solid;
  border-width: 0px 0px 5px 0px;
  border-color: #270BD8;
}
.service-item-name {
  font-size: 18px;
  color: #ffffff;
  height: 50px;
  display: flex;
  justify-content: normal;
}
.service-item-description p {
  height: 180px;
  display: flex;
  text-align: justify;
  color: #ffffff;
}
.service-item-content {
  text-align: right;
  padding: 12px 10px;
  padding-bottom: 25px;
}
.service-item-content a {
  text-decoration: none;
  background: #1790B6;
  padding: 10px 30px;
  border-radius: 50px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 1px solid #ffffff;
}
.all-services-items-type-separator {
  width: 100%;
  height: 5px;
  background: #ffffff;
  margin: 50px 0;
}
.service-item-image-container {
  overflow: hidden;
}
.service-item-image:hover {
  transform: scale(1.2);
}
.service-item-promo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.service-item-promo-col {
  position: relative;
}
.service-item-promo-textual p {
  text-align: center;
  font-size: 25px;
  color: #270BD8;
  font-weight: 600;
}
.service-items-row {
  align-items: center;
}
.service-item-promo-seo-description p {
  text-align: justify;
  font-weight: 500;
}
.app-page-cover-container {
  background: #270BD8;
  color: #ffffff;
}
.app-page-cover-content-body {
  padding: 75px 100px;
}
.app-page-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 600;
}
.app-page-fil-ariane-container {
  text-align: center;
}
.app-page-fil-ariane-link {
  text-decoration: none;
  text-transform: uppercase;
  padding: 10px;
  color: #ffffff;
}
.app-page-fil-ariane-link.active-page {
  font-weight: 900;
}
.page-service-image-cover {
  width: 100%;
}
.service-apropos-section {
  padding: 50px 0;
}
.service-apropos-section-title {
  font-size: 30px;
  color: #1790B6;
  font-weight: 600;
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;
}
.service-apropos-textual-body p {
  text-align: justify;
}
.app-page-service-item-content-body-container {
  display: flex;
  gap: 20px;
  align-items: flex-end;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  padding: 20px;
  border: 1px solid #1790B6;
  border-radius: 10px;
}
.app-page-service-item-image {
  width: 100%;
}
.app-page-service-item-description-container {
  width: 100%;
}
.app-page-service-item-image-container {
  width: 60%;
}
.app-page-service-items-containers {
  margin: 40px 0;
}
.app-page-service-item-title {
  color: #270BD8;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 25px;
}
.app-page-service-item-textual p {
  text-align: justify;
}
.app-page-service-item-col {
  margin-bottom: 20px;
}
.apropos-image-seo {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border: 1px solid #1790B6;
  border-radius: 10px;
}
.apropos-image-row {
  margin: 30px 0;
}
#apropos-page-nos-services {
  background: #1790B6;
}
#apropos-page-nos-services .service-apropos-section-title {
  color: #ffffff;
  margin-bottom: 50px;
}
#apropos-page-nos-services .app-service-card-title {
  margin-top: 10%;
}
#apropos-page-nos-services .app-service-card {
  border: 1px solid #ffffff;
}
#apropos-page-nos-services .app-service-card-textual-body p {
  color: #ffffff;
}
.cta-row {
  margin: 30px 0;
}
.cta-col {
  margin-bottom: 10px;
}
.footer-social-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.apropos-image-col {
  margin-bottom: 20px;
}
.navbar-toggle-container {
  display: flex;
  justify-content: space-between;
}
.mobile-app-header-logo {
  width: 40%;
}
.mobile {
  display: none;
}
.desktop{
  display: block;
}
#rcc-decline-button {
  font-size: 14px;
}
.linkable{
  cursor: pointer;
}

/* Responsive */
@media only screen and (max-width: 768px){
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  .app-header-first-level-body {
    flex-direction: column !important;
    justify-content: center;
    gap: 10px;
  }
  .app-header-first-level.phone-link-container, .app-header-first-level {
    justify-content: center !important;
  }

  .app-header-first-level-container {
      padding: 20px !important;
  }
  .app-logo-link.nav-link {
      position: relative !important;
      display: none !important;
  }
  .app-header-menu-navbar-body {
      flex-direction: column !important;
  }
  #app-default-navbar-nav {
      margin-top: 20px !important;
  }
  .app-homepage-cover-textual-container {
      padding: 20px !important;
  }
  .app-cta-btns-container {
      flex-direction: column !important;
      gap: 20px !important;
      padding: 20px !important;
  }
  .app-service-item-row.row {
      margin: 0 5px !important;
  }
  .app-service-card {
      margin: 40px 0 !important;
  }
  #all-services {
      padding: 20px !important;
  }
  .all-services-section-title-container {
      flex-direction: column !important;
      gap: 20px !important;
  }
  #decoration-section-with-quotation-btn {
      padding: 20px  !important;
  }
  .plumber-section-image {
      display: none !important;
  }
  .plumber-section-body {
      padding: 20px !important;
  }
  .locksmith-section-image {
      display: none !important;
  }
  .locksmith-section-body {
      padding: 20px;
  }
  .footer-first-level-container {
      padding: 20px !important;
  }
  .google-badge-image {
    width: 25% !important;
    right: 5% !important;
  }
  .app-page-cover-content-body {
    padding: 30px !important;
  }
  .service-apropos-section-title {
      padding: 10px !important;
      line-height: 40px;
      text-align: left;
  }
  .all-services-col.plumber-services-col {
      padding: 0 !important;
  }
  .app-page-service-item-content-body-container {
      flex-direction: column !important;
  }
  .app-page-service-item-image-container {
      width: 100% !important;
  }
}

@media only screen and (min-width: 768px){
  .google-badge-image {
    width: 15% !important;
    right: 5% !important;
  }
}
.app-header-first-level.email-link-container {
  text-transform: uppercase;
}
